import React from "react";
import { useNavigate } from "react-router-dom";
import 'remixicon/fonts/remixicon.css';
import './Navbar.css';
import logo from '../../assets/images/logo without back.png';

const Navbar: React.FC = () => {
    const navigate = useNavigate();

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <div className="logo-container">
                    <img src={logo} alt="Logo" className="logo" />
                </div>

                <div className="nav-links">
                    <a href="/">HOME</a>
                    <a href="/#about">OUR STORY</a>
                    <a href="/#products">
                        PRODUCTS
                    </a>

                    <button className="buttons_class">
                        <a href="/#contact">CONTACT</a>
                    </button>
                </div>

                <div className="social-icons">
                    <a target="_blank" className="icon_media" href="https://www.instagram.com/ohmy_good_food/">
                        <i className="ri-instagram-line"></i>
                    </a>
                    <a target="_blank" className="icon_media" href="https://www.linkedin.com/company/oh-my-fr/">
                        <i className="ri-linkedin-box-fill"></i>
                    </a>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
