import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Products from "./pages/products/Products";
import NavResponsive from "./components/Navbar/navResponsive";
import CVG from "./pages/Generality";  // Import de la nouvelle page CGV
import "./App.css";

const AppContent: React.FC = () => {
  const location = useLocation();

  // Liste des pages où on ne veut pas afficher la navbar
  const hideNavbarOn = ["/cvg"];
  const shouldShowNavbar = !hideNavbarOn.includes(location.pathname);

  return (
    <>
      {/* Masquer la navbar et NavResponsive sur /cvg */}
      {shouldShowNavbar && <Navbar />}
      {shouldShowNavbar && <NavResponsive />}

      <Routes>
        {/* Page principale avec Home, About, Products, Contact */}
        <Route
          path="/"
          element={
            <div className="app">
              <div className="sections-container">
                <section id="home" className="section">
                  <Home />
                </section>
                <section id="about" className="section">
                  <About />
                </section>
                <section id="products" className="products">
                  <Products />
                </section>
                <section id="contact" className="section">
                  <Contact />
                </section>
              </div>
            </div>
          }
        />
        {/* Ajouter la route pour les Conditions Générales de Vente */}
        <Route path="/cvg" element={<CVG />} />
      </Routes>
    </>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <AppContent />  {/* Déplacer la logique de useLocation() ici */}
    </Router>
  );
};

export default App;
