import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const words = ["HEALTHY", "DELICIOUS", "ENERGIZING"];

const WavyText: React.FC = () => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % words.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="wavy-container">
            {/* Texte animé */}
            <div className="word-container">
                <AnimatePresence mode="wait">
                    <motion.span
                        key={words[index]}
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 1.2 }}
                        transition={{ duration: 0.6, ease: "easeInOut" }}
                        className="wavy-text"
                    >
                        {words[index]}
                    </motion.span>
                </AnimatePresence>
            </div>
            <span className="static-text"> FOOD FOR AFFORDABLE PRICES</span>
        </div>
    );
};

export default WavyText;
