import React from "react";
import "./Home.css";
import WavyText from "../components/WavyText";

const Home: React.FC = () => {
  return (
    <section id="home" className="home-container">
      <div className="animate_text">
        <WavyText />
        <p>
          At OH MY!, we’re on a mission
          to make healthy, delicious food accessible to everyone! We believe
          everyone deserves a tasty snack that not only fuels your day but also brings
          a smile to your face, anytime, anywhere.

          TO YOUR SNACKS, GET READY.. CRACK ON!
        </p>
        <button className="learn_more">
          <a className="learn_more_button_a" href="/#contact">LEARN MORE</a>
        </button>
      </div>
    </section >
  );
};

export default Home;
