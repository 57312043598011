import React from "react";
import "./Lentil_crisps.css";
import barbecue_image from "../image/BARBECUE-NO-BG.png";
import cheese from "../image/CHEESE TRUFFLE-NO-BG.png"

const Barbecue: React.FC = () => {
    return (
        <div className="barbecue_container">
            <div className="text">
                <div className="name_title">
                    <p className="lentil">LENTIL</p>
                    <h1 className="crisps">CRISPS</h1>
                </div>
                <p className="text_info">
                    Our family of healthy and delicious snacks is getting bigger every year!
                    This year we are very proud to introduce to you our <samp className="lentil_samp">Lentils Crisps;  BBQ and Truffle flavor.</samp> BBQ and Truffle flavor.
                    As always, we’re committed to providing healthy and delicious snacks — <samp className="lentil_samp">palm oil-free, not fried, and packed with 21% protein</samp>
                </p>
            </div>

            <div className="img_cheese">
                <img src={barbecue_image} id="barbecue_img" className="barbecue_img" />
                <img src={cheese} id="cheese_lentils" className="cheese_lentils" />
            </div>
        </div>
    );
};

export default Barbecue;