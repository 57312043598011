import React, { useRef, useState } from "react";
import "./contact.css";
import 'remixicon/fonts/remixicon.css';
import logo from "../assets/images/logo oh my.png";
import emailjs from '@emailjs/browser';

const Contact: React.FC = () => {
  const form = useRef<HTMLFormElement>(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [statusMessage, setStatusMessage] = useState<{ text: string; type: "success" | "error" | null }>({
    text: "",
    type: null,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (!form.current) return;

    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID!,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID!,
      form.current!,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY!
    )
      .then(() => {
        setStatusMessage({ text: "Message sent successfully!.", type: "success" });
        setFormData({ name: "", email: "", message: "" });

        setTimeout(() => setStatusMessage({ text: "", type: null }), 5000);
      })
      .catch((error) => {
        console.error("FAILED...", error.text);
        setStatusMessage({ text: "An error occurred. Please try again later ", type: "error" });

        setTimeout(() => setStatusMessage({ text: "", type: null }), 5000);
      });
  };

  return (
    <section id="contact">
      <div id="contact_section" className="contact_section">
        <div className="localisation">
          <div className="location">
            <div className="map">
              <i className="ri-map-pin-fill"></i>
              229 rue Solférino, 59000 Lille, France
            </div>
            <div className="map">
              <i className="ri-mail-line"></i>
              Cecile@ohmy.fr
            </div>
            <div className="general_condition">
              <a href="/cvg" target="_blank">General Coditions of sale and legal notices</a>
            </div>
          </div>
          <div className="contact">
            <a target="_blank" href="https://www.instagram.com/ohmy_good_food/">
              <i className="ri-instagram-fill"></i>
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/oh-my-fr/">
              <i className="ri-linkedin-box-fill"></i>
            </a>
          </div>
          <p className="power">
            <img className="logo" src={logo} alt="logo" />
            Copyright © 2025 OH MY! | Powered by OH MY!
          </p>
        </div>

        <div className="get_in_touch">
          <form ref={form} onSubmit={handleSubmit} className="send_message">
            {statusMessage.text && (
              <div className={`status-message ${statusMessage.type}`}>
                {statusMessage.text}
              </div>
            )}
            <div className="name">
              <label>Name</label>
              <input
                type="text"
                name="name"
                className="input inputName"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="name email">
              <label>Email</label>
              <input
                type="email"
                name="email"
                className="input inputEmail"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="name contact_form_area">
              <label className="messageArea">Message</label>
              <textarea
                name="message"
                className="contact_form_input"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" className="submit">Submit</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
