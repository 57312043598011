import React from "react";
import multi_graine_green from "../image/Cracker Basil.png";
import multigraine_red from "../image/Cracker Pumpkin.png";
import multigraine_blue from "../image/Cracker Oregano.png";
import "./multigraines.css";

const MultiGraine: React.FC = () => {
    return (
        <div className="multigraine_container">
            <div className="multigraine_description">
                <div className="multigraine_title_name">
                    <h2 className="multigrain">MULTIGRAIN</h2>
                    <h1 className="cracker">CRACKERS</h1>
                </div>
                <p className="containt">
                    <samp className="samp_multigraine">Hey man, what's crackin’? </samp>

                    Here at OH MY! we cooked up an amazing recipe
                    for our crackers that is not only super healthy, but
                    also tasty!!

                    Each square cracker is made out of <samp className="samp_no_color">100% natural
                        ingredients</samp>  and contains over <samp className="samp_no_color">33% oats</samp>  combined
                    with seeds, whole grains-wheat, corn and rice. This
                    makes them a<samp className="samp_no_color">high source of protein  and fibers. </samp>

                    Each cracker is made with a lot of love,<samp className="samp_multigraine_bold"> no additives
                    or artificial aromas.</samp> And if you are a vegan, dig in!!
                    We don’t use any animal products.
                </p>
            </div>
            <div className="crackers_container">
                <div className="crackers_green_div">
                    <img src={multi_graine_green} className="crackers_green" />
                </div>
                <div className="crackers_red_div">
                    <img src={multigraine_red} className="crackers_red" />
                </div>
                <div className="crackers_blue_div">
                    <img src={multigraine_blue} className="crackers_blue" />
                </div>
            </div>
        </div>
    );
};

export default MultiGraine;