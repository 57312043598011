import React from "react";
import "./Products.css";
import Barbecue from "./contained/Lentil_crisps";
import Granola from "../products/contained/granolas_bar";
import MultiGraine from "./contained/multigraines";
import Muelsi from "./contained/superfood_muelsi";

const Products: React.FC = () => {
    return (
        <div className="all_products">
            <section className="granola_bar">
                <Granola/>
            </section>
            <section className="crackers">
                <MultiGraine/>
            </section>
            <section className="superfood">
                <Muelsi/>
            </section>
            <section className="barbecue">
                <Barbecue/>
            </section>
        </div>
    );
};

export default Products;
