import React from "react";
import superfood_yelow from "../image/14.png";
import superfood_13 from "../image/13.png";
import superfood_14 from "../image/12.png";
import "./superfood_muelsi.css";

const Muelsi: React.FC = () => {
    return (
        <div className="superfood_container">
            <div className="muelsi_img">
                <div className="first_img_muelsi">
                    <img id="first_img_muelsi" src={superfood_yelow} />
                </div>
                <div className="second_img_muelsi">
                    <img id="second_img_muelsi" src={superfood_13} />
                </div>
                <div className="third_img_muelsi">
                    <img id="third_img_muelsi" src={superfood_14} />
                </div>
            </div>
            <div className="description">
                <div className="product_name">
                    <div className="text_muelsi">
                        <h2 className="superfood_muelsi">SUPERFOOD</h2>
                        <h1 className="muelsi">MUELSI</h1>
                    </div>
                </div>
                <div className="text_desc">
                    <samp className="superfood_samp">In a rush?! </samp> Don’t worry,OH MY ! created for you the <samp className="samp_no_color">perfect‘on the go’ breakfast </samp> that is filled with energy, superfood and delicious flavours.
                    Our cups contain many different grains that give you <samp className="superfood_samp">a source of fiber, proteins, calcium and magnesium</samp>. You can take them anywhere as we put a little spoon inside the cup. <samp className="samp_no_color">Just add milk or yogurt and enjoy !</samp>
                </div>
            </div>
        </div>
    );
};

export default Muelsi;