import React from "react";
import "./AboutUs.css";
import 'remixicon/fonts/remixicon.css';
import kids from "../assets/images/kids_img_about.png";
import store from "../assets/images/store.png";
import CompanyCarousel from "../pages/CompanyCarousel";
import jolien from "../assets/images/jolien.png"

const AboutUs: React.FC = () => {
    return (
        <section id="about" className="about-section">
            <div className="description_stat">
                <div className="our_story">
                    <div className="start_div">
                        <h1 className="section_title">OUR STORY</h1>
                        <hr className="our_story_hr" />
                    </div>
                    <div className="all_description">
                        <div className="about_us_stat">
                            <div className="image_section">
                                <img src={jolien} className="jolien" />
                            </div>
                        </div>
                        <div className="who_we_are">
                            <div className="words">
                                <h1 className="start">IT ALL STARTS WITH A GRAIN.</h1>
                                <p>
                                    <samp className="oh_my_samp">OH MY !</samp>
                                    is a French company founded by our wonderful CEO, <samp className="jolien_samp">Jolien</samp>, who’s absolutely crazy about snacking!

                                    Besides her love for snacking, Jolien is <samp className="jolien_samp">passionate</samp> about living a healthylifestyle!
                                    That’s why she embarked on an <samp className="jolien_samp">exciting</samp> journey to create a whole line of <samp className="jolien_samp">healthy</samp>, delicious, and super convenient snacks that you can take anywhere!
                                </p>
                                <p>All these delicious product have one <samp className="jolien_samp">superfood</samp> ingredient in common:<samp className="grain"> GRAINS.</samp></p>
                            </div>
                            <div>
                                <CompanyCarousel />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="picture">
                <div className="all_picture">
                    <div className="store_div">
                        <img src={store} className="store_svg" />
                    </div>
                    <div className="kids_div">
                        <img src={kids} className="kid_svg" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
