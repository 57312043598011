import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import 'remixicon/fonts/remixicon.css';
import './navResponsive.css';
import logo from '../../assets/images/logo without back.png';

const NavResponsive: React.FC = () => {
    const navigate = useNavigate();
    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (
        <>
            <div className="navigation_responsive">
                <div className="logo-container">
                    <img src={logo} alt="Logo" className="logo" />
                </div>
                <div className="center_icon_div">
                    <a className="media" href="https://www.instagram.com/ohmy_good_food/">
                        <i className="ri-instagram-line"></i>
                    </a>
                    <a className="media" href="https://www.linkedin.com/company/oh-my-fr/">
                        <i className="ri-linkedin-box-fill"></i>
                    </a>
                </div>
                <div className="remix_icon" id="nav-toggle" onClick={toggleNav}>
                    <i className="ri-menu-4-line"></i>
                </div>
            </div>

            <nav className={`navbarresponsive ${isNavOpen ? "active" : ""}`}>
                <div className="navbar-container">
                    <div className="nav_responsive">
                        <div className="icon_div">
                            <a className="remix_icon" href="/">
                                <i className="ri-home-5-line"></i>
                                Home
                            </a>
                            <a className="remix_icon" href="/#about">
                                <i className="ri-article-line"></i>
                                Our story
                            </a>
                        </div>
                        <div className="icon_div">
                            <a className="remix_icon" href="/#products">
                                <i className="ri-product-hunt-line"></i>
                                Products
                            </a>
                            <a className="remix_icon" href="/#contact">
                                <i className="ri-message-2-line"></i>
                                Contact
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default NavResponsive;
