import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./CompanyCarousel.css";

import image1 from "../assets/images/xenos.png";
import image2 from "../assets/images/total.png";
import image3 from "../assets/images/maxx.png";
import image4 from "../assets/images/tosano.png";
import image5 from "../assets/images/G20.png";
import image6 from "../assets/images/delhaize.png";
import image7 from "../assets/images/carrefour.png";
import image8 from "../assets/images/esso.png";
import image9 from "../assets/images/franprix.png";
import image10 from "../assets/images/Qatar.png";

const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10];

const CompanyCarousel: React.FC = () => {
    return (
        <section className="carousel-container">
            <div className="carousel_client">
                <h2 className="carousel-title">OUR CLIENTS</h2>

                <Swiper
                    modules={[Autoplay, Navigation, Pagination]}
                    autoplay={{ delay: 3000, disableOnInteraction: false }}
                    loop={true}
                    slidesPerView={3}
                    spaceBetween={30}
                    speed={900}
                    className="company-swiper"
                >

                    {images.map((image, index) => (
                        <SwiperSlide className="carousel-image" key={index}>
                            <img id="carousel-image" src={image} alt={`Company ${index + 1}`} className="carousel-image" />
                        </SwiperSlide>
                    ))}
                    <hr className="our_story_hr_company" />
                </Swiper>
            </div>
        </section>
    );
};

export default CompanyCarousel;
