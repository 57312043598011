import React from "react";
import granola_green from "../image/Bar Vanilla.png";
import granola_blue from "../image/Bar Chocolate.png";
import "./granolas_bar.css"

const GranolaBar: React.FC = () => {
    return (
        <div id="granola_bar_container">
            <div className="granola_img">
                <img src={granola_green} alt="" className="granola_img_green" />
                <img src={granola_blue} alt="" className="granola_img_blue" />
            </div>
            <div className="granola_desciption_div">
                <div className="granola_description">
                    <div className="granola_title">
                        <h2 className="granola">GRANOLA</h2>
                        <h1 className="bar">BAR</h1>
                    </div>
                    <p className="granola_info" id="containt_text">
                        <samp className="samp_sugar">It’s energy in bars…</samp> We stared cooking in our
                        OH MY! Kitchen and came up with the perfect
                        mix of high protein cereals, granola, <samp className="kcla">100%
                            whole grain oats</samp> and Chia seeds.

                        This recipe contains lot’s of flavours and only
                        has  <samp className="kcla">114kcal per bar</samp>.

                        Know that they <samp className="samp_sugar">don’t contain any added sugar </samp>!
                        They are filled with vitamin A, B, D, high doses
                        of iron, calcium, copper, zinc,...
                    </p>
                </div>
            </div>
        </div>
    );
};

export default GranolaBar;